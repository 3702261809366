<template>
  <div v-if="hasPerm('people.view_entity')" style="display: inline-block">
    <loading-gif :short="true" :loading-name="loadingName"></loading-gif>
    <send-email-modal
      :name="id"
      :title="confirmSendingTitle"
      :text="confirmSendingText"
      init-subject=""
      :init-from-email="fromEmail"
      :body-required="true"
      @confirmed="onConfirmSending($event)"
    ></send-email-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import SendEmailModal from '@/components/Modals/SendEmailModal.vue'
import store from '@/store'

export default {
  name: 'emails-sender',
  components: {
    LoadingGif,
    SendEmailModal,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    id: String,
    emails: Array,
  },
  data() {
    return {
      loadingName: 'emails-sender',
    }
  },
  computed: {
    fromEmail() {
      return store.getters.userEmail
    },
    confirmSendingTitle() {
      return 'Envoi d\'un email'
    },
    confirmSendingText() {
      let text = ''
      if (this.emails.length === 1) {
        const email = this.emails[0]
        text = 'Souhaitez-vous envoyer un email à ' + email
      } else if (this.emails.length > 1) {
        text = 'Souhaitez-vous envoyer un e-mail à ' + this.emails.length + ' contacts'
      }
      if (text) {
        text += ' depuis le serveur millibase.net?\nL\'envoi ne sera pas immédiat et mis en file d\'attente'
      }
      return text
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onConfirmSending(event) {
      this.startLoading(this.loadingName)
      let url = '/contact/api/send-email-to-contacts-batch/'
      const data = {
        emails: this.emails,
        subject: event.subject,
        body: event.body,
        sender: event.fromEmail,
      }
      const backendApi = new BackendApi('post', url)
      try {
        await backendApi.callApi(data)
        await this.addSuccess('L\'email est en cours d\'envoi')
        this.$emit('done')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
